@import '../../../../../../../styles/shared';

.item {
  padding-bottom: 22px;
}

.prefix {
  padding-right: 4px;
}

.suffix {
  padding-left: 4px;
}
