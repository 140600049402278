@import '../../../../../../../styles/shared';

.subsectionHeading {
  display: flex;
  font-family: var(--hub-module-heading-font-family);
  font-size: 50px;
  line-height: 80px;
  font-weight: 300;

  @include mq($from: desktop) {
    font-size: 70px;
    line-height: 112px;
  }
}
