@import '../../../../../../../styles/shared';

.subsectionContent {
  font-size: 14px;
  line-height: 20px;

  @include mq($from: desktop) {
    font-size: 16px;
    line-height: 28px;
  }
}
