@import '../../../../../../styles/shared';

.subsectionWrapper {
  padding-top: 0;

  @include mq($from: desktop) {
    padding-top: 36px;
  }
}

.ctaButton {
  margin-top: 24px;

  @include mq($from: desktop) {
    margin-top: 34px;
  }
}
